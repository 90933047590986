<template>
  <div>
    <el-card>
      <!-- 第二行，筛选 -->
      <div class="secondrow">
        <!-- 根据名查询 -->
        <div class="input">
          会员账号：
          <el-input
            placeholder="请输入会员账号"
            v-model="queryInfo.mobile"
            clearable
            @clear="getRecordList"
            @change="getRecordList"
            class="select1"
          >
            <el-button slot="append" @click="getRecordList">搜索</el-button>
          </el-input>
        </div>
        <!-- 根据状态查询 -->
        <div class="input2">
          状态：
          <el-select
            v-model="queryInfo.couponUseStatus"
            clearable
            @clear="getRecordList"
            placeholder="请选择使用状态"
            @change="getRecordList"
            class="select2"
          >
            <el-option
              v-for="item in statusList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- 内容区域 -->
      <el-table :data="recordList" stripe style="width: 100%" border>
        <!-- 第一列id -->
        <el-table-column
          label="用户id"
          width="100"
          aligin="center"
          prop="userId"
        >
        </el-table-column>
        <!-- 名称 -->
        <el-table-column label="会员账号" aligin="center" prop="mobile">
        </el-table-column>
        <!-- 领取时间 -->
        <el-table-column label="用户昵称" aligin="center" prop="userName">
        </el-table-column>
        <!-- 领取时间 -->
        <el-table-column label="领取时间" aligin="center" prop="createTime">
        </el-table-column>
        <!-- 状态 -->
        <el-table-column label="状态" aligin="center">
          <template slot-scope="scope">
            <div v-if="scope.row.couponUseStatus === 0">
              未使用
            </div>
            <div v-else>已使用</div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[15, 30, 50, 100]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 请求入参
      queryInfo: {
        account: '',
        couponId: undefined, // 券id
        couponUseStatus: undefined, // 状态，0未使用，1已使用
        limit: 15,
        pageNo: 1,
        mobile: ''
      },
      total: 0,
      // 券列表
      recordList: [],
      // 状态
      statusList: [
        {
          id: 0,
          name: '未使用'
        },
        {
          id: 1,
          name: '已使用'
        }
      ]
    }
  },
  created() {
    // 获取id
    this.queryInfo.couponId = this.$route.query.id
    // 调用获取领取记录
    this.getRecordList()
  },
  methods: {
    // 定义方法
    async getRecordList() {
      const { data: res } = await this.$http.get('agriculturalCoupon/record', {
        params: this.queryInfo
      })
      if (res.code !== 0) {
        return this.$message.error('获取领取记录失败')
      }
      // 赋值
      this.recordList = res.data.data
      this.total = res.data.total
    },
    // limit变化，调用列表接口
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize
      this.getRecordList()
    },
    // 页码变化，调用接口
    handleCurrentChange(newPage) {
      this.queryInfo.pageNo = newPage
      this.getRecordList()
    }
  }
}
</script>

<style lang="less" scoped>
.secondrow {
  display: flex;
  margin-left: -20px;
}
.input {
  display: flex;
  width: 340px;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}
.input:nth-child(1) {
  margin-left: 20px;
}
.select1 {
  width: 260px;
}
</style>
